import ContactForm from "../components/ContactForm/ContactForm"
import Layout from "../components/Layout"
import React from "react"
import SEO from "../components/seo"

const BookingPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Make a booking" />
      <div className="quote-page__form-container margin-top">
        <ContactForm title="Make a booking" type="booking" />
      </div>
    </Layout>
  )
}

export default BookingPage
